// import React from "react";
// import { ToastContainer } from "react-toastify";

// import Identification from "./views/pages";
// import DimsMobile from "./views/pages/dimsmobile";
// import Riskcovry from "./views/pages/riskcovry";
// import AirtelPaymentBank from "./views/pages/apb";
// import MedicalMobile from "./views/pages/medicalmobile";

// const App = () => {
//   return (
//     <div>
//       <Identification />
//       {/* <AirtelPaymentBank /> */}
//       {/* <Riskcovry /> */}
//       {/* <DimsMobile /> */}
//       {/* <MedicalMobile /> */}
//       <ToastContainer position="top-right" />
//     </div>
//   );
// };
// export default App;

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Identification from "./views/pages";
// import Riskcovry from "./views/pages/riskcovry";
import Dimsmobile from "./views/pages/dimsmobile";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route exact path="/" element={<Riskcovry />} /> */}

        {/* <Route exact path="/" element={<Identification />} /> */}

        {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}
        <Route exact path="/" element={<Dimsmobile />} />
      </Routes>
    </Router>
  );
}

export default App;
